// Build information, automatically generated by `ng-info`
const build = {
    version: "2.24.0",
    timestamp: "Tue Mar 18 2025 15:44:04 GMT-0400 (Eastern Daylight Saving Time)",
    message: null,
    git: {
        user: "Patrick Ziajski",
        branch: "HEAD",
        hash: "0a9c84",
        fullHash: "0a9c847aa4d91fd2b742c702788d572cc23a907e"
    }
};

export default build;